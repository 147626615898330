import { createWebHashHistory, createRouter } from "vue-router";

const routes =  [
  //MC
  {
    path: "/getallcoursemc",
    name: "getallcoursemc",
    component: () => import("./components/learningspaces/GetAllCourseMC")
  },
  {
    path: "/managemcs",
    name: "managemcs",
    component: () => import("./components/learningspaces/ManageMCs")
  },
  //KCs 
  {
    path: "/getcourselist",
    name: "getcourselist",
    component: () => import("./components/learningspaces/GetAllCourseKC")
  },
  {
    path: "/managekcs",
    name: "managekcs",
    component: () => import("./components/learningspaces/ManageKCs")
  },
  {
    path: "/assessmentlist",
    name: "assessmentlist",
    component: () => import("./components/assessment/AssessmentList")
  },
  //prompts
  {
    path: "/getcourselistforprompts",
    name: "getcourselistforprompts",
    component: () => import("./components/prompts/GetAllPrompts")
  },
  {
    path: "/showallprompts",
    name: "showallprompts",
    component: () => import("./components/prompts/ShowAllPrompts")
  },
  //questions 
  {
    path: "/getprecannedquestions",
    name: "getprecannedquestions",
    component: () => import("./components/questions/GetPreCannedQuestions")
  },
  //guardrails
  {
    path: "/getprecannedrails",
    name: "getprecannedrails",
    component: () => import("./components/guardrails/ManageGR.vue")
  },
  //chat
  {
    path: "/qna",
    name: "qna",
    component: () => import("./components/chat/QnAComponent")
  },
  //editor
  {
    path: '/editor/:contentId',
    name: 'EditorComponent',
    component: () => import("./components/learningspaces/EditorComponent"),
    props: true
  },
  //login
  {
    path: "/login",
    name: "login",
    component: () => import("./components/auth/LoginUser")
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./components/auth/LogoutUser")
  },
  //archived content
  {
    path: "/archivedcontent/:courseId/:entityId",
    name: "archivedContent",
    component: () => import("./components/learningspaces/ArchivedContent"),
    props: true
  },
  // Default route for root path
  {
    path: "/",
    redirect: "/login"
  },
  //Email testing
    {
      path: "/sendeEmail",
      name: "sendeEmail",
      component: () => import("./components/mail/EMail")
    }

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;